import { Container, Image } from "@mantine/core";
import styles from "./WhyUseIt.module.css";

type WhyUseItProps = {};

export default function WhyUseIt(props: WhyUseItProps) {
  return (
    <section className={styles.wrapper}>
      <a id="features"></a>
      <Container className={styles.container}>
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            <Image className={styles.image} src="/assets/people.svg" alt="Server Image" />
          </div>
          <div className={styles.copyContainer}>
            <ul>
              <li>
                <strong>Multichannel Integration:</strong> Seamlessly connect with your audience through chatbots, SMS,
                phone systems, and more, ensuring your services are available wherever your users are.
              </li>
              <li>
                <strong>Multilingual Support:</strong> Break language barriers with AI-powered translation and
                transcription services, making your content accessible to a global audience.
              </li>
              <li>
                <strong>Enhanced Accessibility:</strong> Go beyond traditional accessibility by customizing how your
                content is delivered, ensuring it meets the unique needs of every user.
              </li>
              <li>
                <strong>Scalable Solutions:</strong> Whether you're a startup or a large organization, our AI tools
                scale with your needs, allowing you to expand your reach without compromising quality.
              </li>
              <li>
                <strong>User-Centric Customization:</strong> Tailor your content delivery to match the preferences and
                behaviors of your audience, enhancing engagement and satisfaction.
              </li>
              <li>
                <strong>Data-Driven Insights:</strong> Utilize AI analytics to understand how your audience interacts
                with your content across different channels, enabling continuous improvement and optimization.
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </section>
  );
}
