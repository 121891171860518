import { Container } from "@mantine/core";
import styles from "./WhatIsIt.module.css";

type WhatIsItProps = {};

export default function WhatIsIt(props: WhatIsItProps) {
  return (
    <section className={styles.wrapper}>
      <div className={styles.inner}>
        <a id="whatIsIt"></a>
        <Container className={styles.container}>
          <p>
            At <strong>Subroutine</strong>, we harness the power of AI to transform how you deliver your products and
            services. Our comprehensive suite of AI tools ensures your offerings reach a broader, more diverse audience
            by providing multiple, meaningful ways to access your content.
          </p>
        </Container>
      </div>
    </section>
  );
}
