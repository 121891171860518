import { Button, Container } from "@mantine/core";
import { IconCheck, IconCloud, IconCurrencyDollar, IconServer } from "@tabler/icons-react";
import styles from "./Plans.module.css";

type PricingProps = {};

export default function Plans(props: PricingProps) {
  return (
    <section className={styles.wrapper}>
      <a id="plans"></a>
      <Container className={styles.container}>
        <h2>Our Plans</h2>

        <div className={styles.plansContainer}>
          <div className={styles.plan}>
            <h3>Cloud</h3>

            <ul className={styles.planFeatures}>
              <li>
                <IconCheck />
                <strong>Security Features:</strong> Comprehensive security measures included.
              </li>
              <li>
                <IconCheck /> <strong>AI Features:</strong> Categorization, Summarization, Transcription, Translation,
                Content Creation, etc.
              </li>
              <li>
                <IconCheck /> <strong>Accessibility Features:</strong> Full suite of accessibility tools.
              </li>
              <li>
                <IconCloud /> <strong>Tenancy:</strong> Shared tenancy.
              </li>
              <li>
                <IconCurrencyDollar /> <strong>Pricing Model:</strong> Volume-based pricing.
              </li>
            </ul>

            <Button color="blue.8" component="a" href="/contactUs?category=sales" className={styles.getStarted}>
              Get Started
            </Button>
          </div>

          <div className={styles.plan}>
            <h3>Managed</h3>

            <ul className={styles.planFeatures}>
              <li>
                <IconCheck /> <strong>Security Features:</strong> Comprehensive security measures included.
              </li>
              <li>
                <IconCheck /> <strong>AI Features:</strong> Categorization, Summarization, Transcription, Translation,
                Content Creation, etc.
              </li>
              <li>
                <IconCheck />
                <strong>Accessibility Features:</strong> Full suite of accessibility tools.
              </li>
              <li>
                <IconServer /> <strong>Tenancy:</strong> Dedicated cluster per customer.
              </li>
              <li>
                <IconCurrencyDollar /> <strong>Pricing Model:</strong> Higher up-front server fees with lower price per
                request/API call.
              </li>
            </ul>

            <Button color="purple" component="a" href="/contactUs?category=sales" className={styles.getStarted}>
              Get Started
            </Button>
          </div>
        </div>

        <p className={styles.plansFootnote}>*Contact us about self-hosting options.</p>
      </Container>
    </section>
  );
}
