import { Button, Container } from "@mantine/core";
import styles from "./TalkToSales.module.css";

type TalkToSalesProps = {
  hideCallToAction?: boolean;
};

export default function TalkToSales({ hideCallToAction }: TalkToSalesProps) {
  return (
    <section className={styles.wrapper}>
      <div className={styles.inner}>
        <a id="talkToSales"></a>
        <Container className={styles.container}>
          {!hideCallToAction && <h2>Ready to get started?</h2>}
          <Button color="blue.9" component="a" href="/contactUs?category=sales" autoContrast>
            Talk to Sales
          </Button>
        </Container>
      </div>
    </section>
  );
}
