import { MetaFunction } from "@remix-run/react";
import SecurityFeatures from "~/components/home/features/SecurityFeatures";
import Plans from "~/components/home/Plans";
import SubroutineHero from "~/components/home/SubroutineHero";
import TalkToSales from "~/components/home/TalkToSales";
import WhatIsIt from "~/components/home/WhatIsIt";
import WhyUseIt from "~/components/home/WhyUseIt";
import styles from "./_home._index.module.css";

export const meta: MetaFunction = () => {
  const title = "Subroutine";
  return [
    { title },
    {
      property: "og:title",
      content: title,
    },
    {
      name: "description",
      content: "Subroutine AI",
    },
  ];
};

export default function Home() {
  return (
    <>
      <main className={styles.main}>
        <SubroutineHero />
        <TalkToSales />
        <WhatIsIt />
        <WhyUseIt />
        <SecurityFeatures />
        {/* <KeyFeatures /> */}
        <Plans />
        <TalkToSales hideCallToAction />
      </main>
    </>
  );
}
