import { Container } from "@mantine/core";
import { useHash } from "@mantine/hooks";
import Rocket from "../common/Rocket";
import styles from "./SubroutineHero.module.css";

type SubroutineHeroProps = {};

export default function SubroutineHero(props: SubroutineHeroProps) {
  const [hash, setHash] = useHash();

  return (
    <section className={styles.wrapper}>
      <Container className={styles.container}>
        <div className={styles.background}>
          <Rocket className={styles.rocket} />
        </div>
        <h1>
          Reach More People, Support More Languages, <br />
          Engage on More Channels, Enhance Accessibility
        </h1>
        <h2>
          AI-powered tools to make your services accessible, multilingual, and available through diverse communication
          channels.
        </h2>
      </Container>
    </section>
  );
}
