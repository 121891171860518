import { Container } from "@mantine/core";
import {
  IconCloudLock,
  IconDatabase,
  IconLink,
  IconShieldLock,
  IconTransform,
  IconUserCheck,
} from "@tabler/icons-react";
import styles from "./SecurityFeatures.module.css";

type SecurityFeaturesProps = {};

export default function SecurityFeatures(props: SecurityFeaturesProps) {
  return (
    <section className={styles.wrapper}>
      <a id="security"></a>
      <div className={styles.leadIn}>
        <Container className={styles.leadInContainer}>
          <h2>
            At <strong>Subroutine</strong>, your security is at the heart of everything we do. We implement
            state-of-the-art measures to ensure your data remains safe, private, and accessible only to those who need
            it. Our comprehensive security framework is designed to protect your organization from evolving threats
            while enabling seamless access and functionality.
          </h2>
        </Container>
      </div>
      <Container className={styles.container}>
        <div className={styles.imageWrapper}>
          <h3>Key Security Features:</h3>
          <div className={styles.copyContainer}>
            <ul>
              <li>
                <div>
                  <IconCloudLock />
                  <span>
                    <strong>Flexible Deployment Options:</strong>
                    We provide flexible deployment choices, including fully managed installations that support secure
                    VPN/VPC peering (with VPN provided by you) and scalable, efficient cloud-only solutions to meet your
                    organization’s unique needs.
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <IconUserCheck />
                  <span>
                    <strong>Comprehensive Access Management:</strong>
                    Our access management system integrates SCIM and Single Sign-On (SSO) to streamline user
                    provisioning and authentication, while Role-Based Access Control (RBAC) and Policy-Based Access
                    Control (PBAC) ensure precise and granular permissions.
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <IconShieldLock />
                  <span>
                    <strong>Robust API Security:</strong>
                    We secure all API interactions with access tokens that automatically expire and require forced
                    refreshes, minimizing the risk of unauthorized access and ensuring safe and reliable API usage.
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <IconDatabase />
                  <span>
                    <strong>Data Protection:</strong>
                    All your data is encrypted both in transit and at rest, providing multiple layers of security to
                    safeguard your sensitive information from interception and breaches.
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <IconTransform />
                  <span>
                    <strong>Minimized Attack Surface:</strong>
                    By limiting the exposed surface area of our systems, we reduce potential vulnerabilities.
                    Additionally, all internal traffic is managed through asynchronous job queues and workers, ensuring
                    secure and efficient data processing.
                  </span>
                </div>
              </li>
              <li>
                <div>
                  <IconLink />
                  <span>
                    <strong>Trusted Integrations:</strong>
                    We integrate exclusively with SOC 2 compliant external vendors, ensuring that our integrations
                    adhere to the highest security standards and maintain rigorous protection of your data.
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </section>
  );
}
